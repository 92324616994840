import { Component, OnInit, Input, } from '@angular/core';
import { Router, } from '@angular/router';

@Component({
  selector: 'app-projects-header',
  templateUrl: './projects-header.component.html',
  styleUrls: ['./projects-header.component.scss']
})
export class ProjectsHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() content: string;
  @Input() location: string;
  @Input() imageUrl: string;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onNavigate() {
    this.router.navigate(['/home'], {fragment: 'my-projects'})
  }

}
