import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-next-project-nav',
  templateUrl: './next-project-nav.component.html',
  styleUrls: ['./next-project-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextProjectNavComponent implements OnInit {
  @Input() previousUrl: string;
  @Input() nextUrl: string;
  @Input() index: number;
  numberOfProjects: number = 6;

  constructor() { }

  ngOnInit() {
  }

}
