import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { take, } from 'rxjs/operators';

import HomeHeroChat from '../../data/home-hero-chat.json';
import HomeContactChat from '../../data/home-contact-chat.json';
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  homeHeroChat = HomeHeroChat;
  homeContactChat = HomeContactChat;
  homeHeroChatMessageDelay: Array<number> = [1000, 3000, 2000, 2000, 4000];
  homeContactChatMessageDelay: Array<number> = [0];
  year = (new Date).getFullYear();
  numberOfProjects: number = 6;
  mobileProjectInViewActiveBreadcrumb: number = 0;

  projectList: Element;
  projectListScrollPosition: number = 0;
  projectListScrollWidth: number = 0;
  currentProjectListIndex: number = 0;

  fragment: string;
  animateChat: boolean;

  @ViewChild('pastWorkCardList', { static: true }) pastWorkCardList: ElementRef;

  constructor(private route: ActivatedRoute,
    private homeService: HomeService) { }

  ngOnInit() {
    this.animateChat = this.homeService.getChatAnimate();
    this.route.fragment
      .pipe(take(1))
      .subscribe(fragment => { 
        this.fragment = fragment; 
      });
  }

  ngAfterViewInit() {
    this.projectList = document.getElementById('pastWorkCardList');

    if (this.projectList) {
      this.projectListScrollWidth = this.projectList.scrollWidth;
    }

    if (this.fragment) {
      document.querySelector('#' + this.fragment).scrollIntoView();
    }

    if (this.animateChat) {
      this.homeService.setChatAnimate(false);
    } 
  }

  onScroll() {
    if (this.projectListScrollWidth) {
      this.projectListScrollPosition = this.projectList.scrollLeft;
      const sliceWidths = this.projectListScrollWidth / this.numberOfProjects;
      this.currentProjectListIndex = Math.floor((this.projectListScrollPosition + (sliceWidths * 0.5)) / sliceWidths);
    }
  }

}