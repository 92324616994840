import { Component, OnInit, ChangeDetectionStrategy, Input, Renderer2, ViewChild, ElementRef, } from '@angular/core';
import { Router, } from '@angular/router';

@Component({
  selector: 'app-past-work-card',
  templateUrl: './past-work-card.component.html',
  styleUrls: ['./past-work-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PastWorkCardComponent implements OnInit {
  @Input() title: string;
  @Input() date: string;
  @Input() imageUrl: string;
  @Input() tags: Array<string> = [];
  @Input() color: string;
  @Input() url: string;
  @Input() imageStyle: string;
  @Input() backgroundImageUrl: string;
  @Input() backgroundMobileImageUrl: string;
  @Input() titleOverlayRequired: boolean;
  @ViewChild('card', { static: true }) card: ElementRef;
  @ViewChild('imageContainer', { static: true }) imageContainer: ElementRef;

  constructor(private router: Router,
    private renderer: Renderer2) { }

  ngOnInit() {
    if (this.color) {
      //this.renderer.setStyle(this.card.nativeElement, 'background-color', this.color);
    }

    if (this.imageStyle) {
      //this.renderer.addClass(this.imageContainer.nativeElement, this.imageStyle);
    }

    if (this.backgroundImageUrl && window.innerWidth > 1200) {
      this.renderer.setStyle(this.card.nativeElement, 'background-image', 'url(' + this.backgroundImageUrl + ')');
    }

    if (this.backgroundMobileImageUrl && window.innerWidth <= 1200) {
      this.renderer.setStyle(this.card.nativeElement, 'background-image', 'url(' + this.backgroundMobileImageUrl + ')');
    }
  }

  onNavigate() {
    if (this.url) {
      this.router.navigate(['projects/' + this.url]);
    }
  }

}
