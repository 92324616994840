import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-school-nutrition',
  templateUrl: './project-school-nutrition.component.html',
  styleUrls: ['./project-school-nutrition.component.scss']
})
export class ProjectSchoolNutritionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
