import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-free-reduced-meals',
  templateUrl: './project-free-reduced-meals.component.html',
  styleUrls: ['./project-free-reduced-meals.component.scss']
})
export class ProjectFreeReducedMealsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
