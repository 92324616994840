import { Component, OnInit, OnDestroy, HostListener, } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, } from '@angular/router';
import { Subject, } from 'rxjs';
import { filter, takeUntil, } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject();
  showMenuFab: boolean = false;
  menuToggle: boolean = false;
  
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((e: RouterEvent) => e instanceof NavigationEnd),
        takeUntil(this.destroy$))
      .subscribe(_ => {
        window.scrollTo(0, 0);
      })

    this.updateShowMenuFab();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateShowMenuFab();
  }

  updateShowMenuFab() {
    if (window.innerWidth < 992 && this.showMenuFab === false) {
      this.showMenuFab = true;
    } else if (window.innerWidth >= 992 && this.showMenuFab) {
      this.showMenuFab = false;
    }

    this.menuToggle = false;
  }

  onFabClicked() {
    this.menuToggle = !this.menuToggle;
  }

  onMenuNavigate() {
    this.menuToggle = false;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
