import { Component, OnInit, HostListener, Renderer2, ElementRef, ViewChild, } from '@angular/core';
import { Router, } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  host: {'class': 'app-navigation'}
})
export class NavigationComponent implements OnInit {
  @ViewChild('navbar', { static: true }) navbar: ElementRef;

  constructor(private render: Renderer2,
    private router: Router) { }

  ngOnInit() {
    this.checkNavbarPosition();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.checkNavbarPosition();
  }

  checkNavbarPosition() {
    if (window.pageYOffset > 20) {
      if (!this.navbar.nativeElement.classList.contains('fixed')) {
        this.render.addClass(this.navbar.nativeElement, 'fixed');
      }
    } else {
      this.render.removeClass(this.navbar.nativeElement, 'fixed');
    }
  }

  onNavigateHome() {
    this.router.navigate(['/home']);
  }

}
