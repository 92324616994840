import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, ViewChild, ElementRef, Renderer2, } from '@angular/core';
import { BehaviorSubject, } from 'rxjs';

import { ChatData, ChatDataSpeakerLocation, } from '../models';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
  host: {'class': 'app-chat-window'},
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatWindowComponent implements OnInit, OnChanges {
  @Input() data: ChatData;
  @Input() chatMessageDelay: Array<number>;
  @Input() defaultMessageSpeed: number = 2000;
  @Input() defaultMessageStartSpeed: number;
  @Input() locationDelay: number;
  @Input() showListenerContactRequest: boolean;
  @Input() css: string;
  @ViewChild('chatWindow', { static: true }) chatWindowElemRef: ElementRef;
  speakerMessages$: BehaviorSubject<Array<{ message: string }>> = new BehaviorSubject([]);
  speakerLocation$: BehaviorSubject<ChatDataSpeakerLocation> = new BehaviorSubject(null);
  messageSpeed: number = this.defaultMessageStartSpeed !== undefined ? this.defaultMessageStartSpeed : 2000;
  months: Array<string> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  speakerDate: string;
  today: Date = new Date();

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    this.speakerDate = this.months[this.today.getMonth()] + ' ' + this.today.getDate() + ' at ';

    if (this.css && this.chatWindowElemRef) {
      this.renderer.addClass(this.chatWindowElemRef.nativeElement, this.css);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && this.data && this.data.speakerMessages) {
      this.messageSpeed = this.defaultMessageStartSpeed !== undefined ? this.defaultMessageStartSpeed : 2000;
      this.data.speakerMessages.forEach((message: { message: string }, index: number) => {
        setTimeout(_ => {
          this.speakerMessages$.next([
            ...this.speakerMessages$.value,
            message,
          ]);
        }, this.messageSpeed);
        if (this.chatMessageDelay !== undefined && this.chatMessageDelay.length >= index) {
          this.messageSpeed += this.chatMessageDelay[index];
        } else {
          this.messageSpeed += this.defaultMessageSpeed;
        }
      });
    }

    if (changes['locationDelay'] && this.data && this.data.speakerLocation) {
      setTimeout(_ => this.speakerLocation$.next(this.data.speakerLocation), this.locationDelay);
    }
  }

}
