import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-developer-toolkit',
  templateUrl: './project-developer-toolkit.component.html',
  styleUrls: ['./project-developer-toolkit.component.scss']
})
export class ProjectDeveloperToolkitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
