import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private chatAnimate: boolean = true;

  constructor() { }

  getChatAnimate() {
    return this.chatAnimate;
  }

  setChatAnimate(animate: boolean) {
    this.chatAnimate = animate;
  }
}
