import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectClaimProcessingComponent } from './project-claim-processing/project-claim-processing.component';
import { ProjectFreeReducedMealsComponent } from './project-free-reduced-meals/project-free-reduced-meals.component';
import { ProjectSchoolPaymentsComponent } from './project-school-payments/project-school-payments.component';
import { ProjectSchoolNutritionComponent } from './project-school-nutrition/project-school-nutrition.component';
import { ProjectDeveloperToolkitComponent } from './project-developer-toolkit/project-developer-toolkit.component';
import { ProjectBusinessPosComponent } from './project-business-pos/project-business-pos.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { 
    path: 'projects', 
    component: ProjectsComponent,
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'healthcare-claims-processing', component: ProjectClaimProcessingComponent },
      { path: 'free-reduced-school-meals', component: ProjectFreeReducedMealsComponent },
      { path: 'k12-school-payments', component: ProjectSchoolPaymentsComponent },
      { path: 'k12-school-nutrition', component: ProjectSchoolNutritionComponent },
      { path: 'developer-toolkits', component: ProjectDeveloperToolkitComponent },
      { path: 'business-solutions-pos', component: ProjectBusinessPosComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
