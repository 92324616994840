import { Component, OnInit, ChangeDetectionStrategy, Input, } from '@angular/core';

@Component({
  selector: 'app-home-past-work-breadcrumbs',
  templateUrl: './home-past-work-breadcrumbs.component.html',
  styleUrls: ['./home-past-work-breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePastWorkBreadcrumbsComponent implements OnInit {
  @Input() numberOfProjects: number;
  @Input() activeIndex: number;
  array: Array<number>;

  constructor() { }

  ngOnInit() {
  }

}
