import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatWindowComponent } from './chat-window/chat-window.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { PastWorkCardComponent } from './past-work-card/past-work-card.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { pipes } from './pipes';
import { HomePastWorkBreadcrumbsComponent } from './home-past-work-breadcrumbs/home-past-work-breadcrumbs.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectClaimProcessingComponent } from './project-claim-processing/project-claim-processing.component';
import { ProjectFreeReducedMealsComponent } from './project-free-reduced-meals/project-free-reduced-meals.component';
import { ProjectSchoolPaymentsComponent } from './project-school-payments/project-school-payments.component';
import { ProjectSchoolNutritionComponent } from './project-school-nutrition/project-school-nutrition.component';
import { ProjectDeveloperToolkitComponent } from './project-developer-toolkit/project-developer-toolkit.component';
import { ProjectBusinessPosComponent } from './project-business-pos/project-business-pos.component';
import { ProjectsHeaderComponent } from './projects-header/projects-header.component';
import { HomeService } from './home/home.service';
import { NextProjectNavComponent } from './next-project-nav/next-project-nav.component';

@NgModule({
  declarations: [
    AppComponent,
    ChatWindowComponent,
    HomeComponent,
    AboutComponent,
    PastWorkCardComponent,
    FooterComponent,
    NavigationComponent,
    ...pipes,
    HomePastWorkBreadcrumbsComponent,
    ProjectsComponent,
    ProjectClaimProcessingComponent,
    ProjectFreeReducedMealsComponent,
    ProjectSchoolPaymentsComponent,
    ProjectSchoolNutritionComponent,
    ProjectDeveloperToolkitComponent,
    ProjectBusinessPosComponent,
    ProjectsHeaderComponent,
    NextProjectNavComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    MatButtonModule,
  ],
  providers: [HomeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
